var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wgD1-ueBz3PucFIVVysSP"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  beforeSend(event) {
    if (
      event.level === "error" ||
      event.level === "warning" ||
      event.level === "fatal" ||
      (event.tags && event.tags.zelevate)
    ) {
      return event;
    }
    return null;
  },
  ...(process.env.NEXT_PUBLIC_NODE_ENV && {
    environment: process.env.NEXT_PUBLIC_NODE_ENV,
  }),
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0,
  enabled: process.env.NODE_ENV === "production",
  // authToken: process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
